/** @format */

export default {
  data() {
    return {
      idItemShow: 0,
      showDetail: false,
    };
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    url() {
      return this.m.api;
    },
  },
  watch: {
    showDetail(v) {
      if (!v) {
        this.idItemShow = 0;
        this.$emit('onClose');
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
  },
};
