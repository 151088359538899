<template>
  <div>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="m"
        :configForm="{ head: configHead, title: configHead.title, api: url }"
        :id="idEdit"
        :opt="{ delete: true }"
      />
    </portal>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:title>
        <v-btn color="primary" @click="onChangeView()">
          <btn-title v-if="view == 1" icon="fas fa-columns">Вид канбан</btn-title>
          <btn-title v-else icon="fas fa-list">Вид таблица</btn-title>
        </v-btn>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title></v-btn>
        <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
      >
      <view-canban
        ref="taskCanban"
        v-if="view == 2"
        :api="url"
        :model="model"
        @show-card="idItemShow = $event"
        :config="{
          params: { filters: { createdby_id: $root.profile.id } },
        }"
      />
      <a-table-f-api v-if="view == 1" ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import mainView from "@/components/libs/mainView";

import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, mainView, genModel],
  components: {
    viewCanban: () => import("@/views/lead/dialogs/leadsCanban.vue"),
    ViewItem: () => import("@/views/lead/views/leadView"),
  },
  data() {
    return {
      view: 1,
      idEdit: 0,
      showEditDialog: false,
      title: "",
      //m: Object.assign({}, this.$store.getters["config/get"].models.leads),

      defaults: {
        filters: { createdby_id: this.$root.profile.id },
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Клиенты";
  },
  computed: {
    configHead() {
      return this.m.config.broker || {};
    },
    url() {
      return this.m.api;
    },
    m() {
      const res = Object.assign({}, this.$store.getters["config/get"].models.leads);
      return res;
    },
    model() {
      let model = this.getModelList(this.m);

      model.forEach((el) => {
        if (el.name == "createdby_id") el["searchable"] = this.getAccess("leads.viewAll");
        if (el.name == "createdby_id") el["hidden"] = !this.getAccess("leads.viewAll");
        if (el.name == "user_id") el["hidden"] = !this.getAccess("leads.viewAll");
        if (el.name == "source") el["hidden"] = !this.getAccess("leads.viewAll");
      });
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      if (this.view === 1) this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    onChangeView() {
      if (this.view == 1) {
        this.view = 2;
      } else {
        this.view = 1;
      }
    },
    onClickRow(d) {
      this.idItemShow = d.row.id;
      return;
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;

      this.showEditDialog = true;
    },
  },
};
</script>
